var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2"},[_vm._v(" แดชบอร์ด ")])])]}}])})],1),_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/wallet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2"},[_vm._v(" กระเป๋าเงิน ")])])]}}])})],1),_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/shop/order"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2 noti"},[_c('span',{staticClass:"menu-m"},[_vm._v(" ใบสั่งซื้อ "),(_vm.getCurrentNoti && _vm.getCurrentNoti.trade)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNoti.trade)+" ")]):_vm._e()])])])]}}])})],1),_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/forwarder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2 noti"},[_c('span',{staticClass:"menu-m"},[_vm._v(" รหัสพัสดุ "),(_vm.getCurrentNoti && _vm.getCurrentNoti.track)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNoti.track)+" ")]):_vm._e()])])])]}}])})],1),_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/shippay/bill"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2"},[_vm._v(" ใบส่งพัสดุ ")])])]}}])})],1),_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/payments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2 noti"},[_c('span',{staticClass:"menu-m"},[_vm._v(" โอนเงินไปจีน "),(_vm.getCurrentNoti && _vm.getCurrentNoti.payment)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNoti.payment)+" ")]):_vm._e()])])])]}}])})],1),(_vm.IS_SELLER)?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/seller/product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2 noti"},[_c('span',{staticClass:"menu-m"},[_vm._v(" มาเป็นผู้ขาย "),(
                _vm.getCurrentNoti &&
                  (_vm.getCurrentNoti.seller_message ||
                    _vm.getCurrentNoti.seller_order)
              )?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(" "+_vm._s(_vm.getCurrentNoti.seller_message + _vm.getCurrentNoti.seller_order)+" ")]):_vm._e()])])])]}}],null,false,1042126200)})],1):_vm._e(),(_vm.getIsAdmin)?_c('li',{staticClass:"menu-item"},[_c('router-link',{attrs:{"to":"/admin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"menu-link",class:[isActive && 'active', isExactActive && 'active'],attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":navigate}},[_c('span',{staticClass:"menu-text fs-2"},[_vm._v(" หน้าแอดมิน ")])])]}}],null,false,2756826)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }