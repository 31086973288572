import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import { ACCOUNT, LOGOUT } from "@/core/services/store/auth.module";
import i18nService from "@/core/services/i18n.service.js";
import { NOTI } from "@/core/services/store/profile.module.js";
import { IS_SELLER } from "@/config";
export default {
  name: "KTTopbar",
  data: function data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
      polling: null,
      IS_SELLER: IS_SELLER
    };
  },
  methods: {
    loadAccount: function loadAccount() {
      this.$store.dispatch(ACCOUNT);
    },
    loadData: function loadData() {
      this.$store.dispatch(NOTI);
    },
    poll: function poll() {
      var _this = this;

      this.polling = setInterval(function () {
        _this.loadData();
      }, 9000);
    },
    onLanguageChanged: function onLanguageChanged() {
      this.languageFlag = this.languages.find(function (val) {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    onLogout: function onLogout() {
      var _this2 = this;

      this.$store.dispatch(LOGOUT).then(function () {
        return _this2.$router.push({
          name: "login"
        });
      });
    },
    onOver: function onOver() {
      this.$refs.dropdown.visible = true;
    },
    onLeave: function onLeave() {
      this.$refs.dropdown.visible = false;
    }
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.polling);
  },
  created: function created() {
    this.loadData();
    this.poll();
    this.loadAccount();
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["currentUser", "getCurrentNoti"])), {}, {
    getLanguageFlag: function getLanguageFlag() {
      return this.onLanguageChanged();
    }
  })
};