import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.exec.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenu from "@/view/layout/header/Menu.vue";
import { IS_SELLER } from "@/config";
import HeaderNoti from "@/view/layout/header/HeaderNoti.vue";
export default {
  name: "KTHeader",
  data: function data() {
    return {
      tabIndex: 0,
      searchItem: "",
      IS_SELLER: IS_SELLER
    };
  },
  components: {
    KTTopbar: KTTopbar,
    KTMenu: KTMenu,
    HeaderNoti: HeaderNoti
  },
  mounted: function mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init(this.$refs["kt_header"], this.$refs["kt_header_mobile"]); // Init Header Menu

    KTLayoutHeaderMenu.init(this.$refs["kt_header_menu"], this.$refs["kt_header_navs"]);
    var headerRef = this.$refs["kt_header_navs"];
    headerRef.querySelectorAll("a.menu-link").forEach(function (item) {
      item.addEventListener("click", function () {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["layoutConfig", "getClasses", "getIsAdmin", "getCurrentNoti"])), {}, {
    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled: function headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses: function headerClasses() {
      var classes = this.getClasses("header");

      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }

      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses: function headerMenuClasses() {
      var classes = this.getClasses("header_menu");

      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }

      return null;
    },

    /**
     * Check if header container is fluid
     */
    widthFluid: function widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    }
  }),
  methods: {
    hasActiveChildren: function hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

    /**
     * Set current active on click
     * @param event
     */
    setActiveTab: function setActiveTab(event) {
      var target = event.target;

      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      var tab = target.closest('[role="tablist"]');
      var links = tab.querySelectorAll(".nav-link"); // remove active tab links

      for (var i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      } // set clicked tab index to bootstrap tab


      this.tabIndex = parseInt(target.getAttribute("data-tab")); // set current active tab

      target.classList.add("active");
    },
    enterSearch: function enterSearch() {
      var url = this.searchItem;
      this.$router.push("/shop/search/" + encodeURIComponent(url.replace(".html", "")));
      this.searchItem = "";
    }
  }
};